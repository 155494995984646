import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const VideoIcon = ({ color = '#666666', ...props }: Props) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.2972 1.59583C20.8568 1.28725 20.3088 1.24621 19.8351 1.48383L15.8889 3.45833V2.66667C15.8889 1.19375 14.7946 0 13.4444 0H2.44444C1.09427 0 0 1.19375 0 2.66667V13.3333C0 14.8063 1.09427 16 2.44444 16H13.4444C14.7946 16 15.8889 14.8063 15.8889 13.3333V12.5408L19.8344 14.5142C20.0385 14.6164 20.2557 14.6672 20.4718 14.6672C20.7595 14.6672 21.0455 14.578 21.2965 14.4022C21.7365 14.0958 22 13.5708 22 12.9625V3C22 2.42917 21.7365 1.90833 21.2972 1.59583ZM14.0556 13.3333C14.0556 13.7009 13.7814 14 13.4444 14H2.44444C2.10749 14 1.83333 13.7009 1.83333 13.3333V2.66667C1.83333 2.29908 2.10749 2 2.44444 2H13.4444C13.7814 2 14.0556 2.29908 14.0556 2.66667V13.3333ZM20.1667 12.4792L15.8889 10.3417V5.65833L20.1667 3.51958V12.4792Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoIcon;
