import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  BriefingUIState,
  GuestUIState,
  GuestUiErrors,
  IntroUIState,
  QuestionnaireUIState,
  SummaryUIState,
  UIState,
  VideoInterviewUIState,
} from './reducer';

const UISlice = (state: RootState) => state.ui;

// GUEST
export const getGuest = createSelector(UISlice, (state: UIState): GuestUIState => state.guest);
export const getGuestLoading = createSelector(getGuest, (state: GuestUIState) => state.loading);
export const getGuestErrors = createSelector(
  getGuest,
  (state: GuestUIState): GuestUiErrors => state.errors
);

// INTRO
export const getIntro = createSelector(UISlice, (state: UIState): IntroUIState => state.intro);
export const getIntroLoading = createSelector(getIntro, (state: IntroUIState) => state.loading);

// SUMMARY
export const getSummary = createSelector(
  UISlice,
  (state: UIState): SummaryUIState => state.summary
);
export const getSummaryLoading = createSelector(
  getSummary,
  (state: SummaryUIState) => state.loading
);

// BRIEFING
export const getBriefing = createSelector(
  UISlice,
  (state: UIState): BriefingUIState => state.briefing
);
export const getBriefingLoading = createSelector(
  getBriefing,
  (state: BriefingUIState) => state.loading
);

// FILTER QUESTIONS
export const getFilterQuestionsLoading = createSelector(
  UISlice,
  (state: UIState): boolean => state.filter_questions.loading
);
// TODO: Change presentation interview into a generic one for interviews
// PRESENTATION INTERVIEW
export const getPresentationInterview = createSelector(
  UISlice,
  (state: UIState): VideoInterviewUIState => state.presentation_interview
);
export const getPresentationInterviewLoading = createSelector(
  getPresentationInterview,
  (state: VideoInterviewUIState): boolean => state.loading
);

// VIDEO INTERVIEW
export const getVideoInterview = createSelector(
  UISlice,
  (state: UIState): VideoInterviewUIState => state.video_interview
);
export const getVideoInterviewLoading = createSelector(
  getVideoInterview,
  (state: VideoInterviewUIState): boolean => state.loading
);

// QUESTIONNAIRE
export const getQuestionnaire = createSelector(
  UISlice,
  (state: UIState): QuestionnaireUIState => state.questionnaire
);
export const getQuestionnaireLoading = createSelector(
  getQuestionnaire,
  (state: QuestionnaireUIState): boolean => state.loading
);

// GUEST INVITED
export const getGuestInvited = createSelector(
  UISlice,
  (state: UIState): { name: string; surname: string; email: string } => state.guestInvited
);
