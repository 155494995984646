import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';
import { FilterQuestionsData } from '../../interfaces/filterQuestionsData';
import { actions as examActions } from '../../store/exam/reducer';
import { getAssessment } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { actions } from '../../store/ui/reducer';
import {
  ExamFoundPayload,
  ExamUpdatedPayload,
  JobPostFoundPayload,
  RetakePayload,
} from './socket.interfaces';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const socket: Socket = io(`${API_BASE_URL}/exam`, {
  // withCredentials: true // TODO:Manage Auth
  transports: ['websocket'], // ['polling', 'websocket']
  timeout: 30000, // default 20000
});

interface HookProps {
  socket: Socket;
  connected: boolean;
}

const useSocket = (): HookProps => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [disconnected, setDisconnected] = useState<boolean>(false);

  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const assessment = useAppSelector(getAssessment);

  const navigate = useNavigate();

  useEffect(() => {
    const connectHandler = () => {
      console.log('[SOCKET] Connected:', socket.connected);
      console.log('[SOCKET] ID:', socket.id);
      if (job_post_alias && application_alias) {
        whoamiHandler(disconnected);
      } else {
        iamguestHandler();
      }
    };

    const disconnectHandler = (
      reason: Socket.DisconnectReason,
      details: DisconnectDescription | undefined
    ) => {
      console.log('Reason:', reason);
      console.log('Details:', details);
      setDisconnected(true);
    };

    const softSkillQuestionInterviewChunkProcessedHandler = ({
      _part,
    }: {
      _part: number;
    }): void => {
      dispatch(examActions.UPDATE_COMPLETED_CHUNKS());
    };
    const presentationInterviewChunkProcessedHandler = ({ _part }: { _part: number }): void => {
      dispatch(examActions.UPDATE_PRESENTATION_INTERVIEW_COMPLETED_CHUNKS());
    };

    const softSkillQuestionInterviewPreviewHandler = (payload: RetakePayload) => {
      dispatch(examActions.SOFT_SKILL_QUESTION_RETAKE(payload));
      navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-preview`, {
        replace: true,
      });
    };

    const whoamiHandler = (disconnected: boolean) => {
      socket.emit('whoami', { job_post_alias, application_alias, silent: disconnected });
      // setDisconnected(false);
    };

    const iamguestHandler = (): void => {
      socket.emit('iamguest', { job_post_alias });
    };

    const guestInvitedHandler = (): void => {
      navigate(`/${job_post_alias}/invited`, { replace: true });
    };

    const examFoundHandler = (payload: ExamFoundPayload) => {
      dispatch(examActions.RETRIEVE_EXAM(payload));
      //If the user is authenticated by the guest form, we do not have the application_alias, but the socket has already sent it to us after the user signed in
      navigate(`/${job_post_alias}/${assessment?.application_alias}`, { replace: true });
      setDisconnected(false);
    };

    const assessmentUpdatedHandler = (payload: ExamUpdatedPayload) => {
      dispatch(examActions.UPDATE_ASSESSMENT(payload));
    };

    const jobPostFoundHandler = (payload: JobPostFoundPayload) => {
      dispatch(examActions.RETRIEVE_JOB_POST(payload));
      setDisconnected(false);
    };

    const okHandler = () => {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    };
    const killerQuestionsSucceeded = () => {
      dispatch(examActions.DISABLE_LOADING());
      navigate(`/${job_post_alias}/${application_alias}/filter-questions-done`, { replace: true });
    };
    const killerQuestionsFailed = (payload: FilterQuestionsData) => {
      dispatch(examActions.KILLER_QUESTIONS_FAILED(payload));
      navigate(`/${job_post_alias}/${application_alias}/filter-questions-done`, { replace: true });
    };

    // Errors
    const companyNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/not-found`, { replace: true });
    };
    const assessmentAlreadyDoneHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/already-done`, { replace: true });
    };
    const jobPostNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/not-found`, { replace: true });
    };
    const applicationNotFoundHandler = (): void => {
      navigate(`/${job_post_alias}/${application_alias}/not-found`, { replace: true });
    };
    const jobPostArchivedHandler = (): void => {
      navigate(`/${job_post_alias}/expired`, { replace: true });
    };
    const guestEmailErrorHandler = (): void => {
      dispatch(
        actions.GUEST_ERROR({ name: 'email', error: t('useSocket.guest.error.duplicateEmail') })
      );
    };

    socket.on('connect', connectHandler);
    socket.on('disconnect', disconnectHandler);
    socket.on(
      'soft-skill-question-interview-chunk-processed',
      softSkillQuestionInterviewChunkProcessedHandler
    );
    socket.on('soft-skill-question-interview-preview', softSkillQuestionInterviewPreviewHandler);
    socket.on('presentation-interview-chunk-processed', presentationInterviewChunkProcessedHandler);
    socket.on('exam-found', examFoundHandler);
    socket.on('job-post-found', jobPostFoundHandler);
    socket.on('assessment-updated', assessmentUpdatedHandler);
    socket.on('guest-invited', guestInvitedHandler);
    socket.on('ok', okHandler);
    socket.on('killer-questions-failed', killerQuestionsFailed);
    socket.on('killer-questions-succeeded', killerQuestionsSucceeded);
    // Errors
    socket.on('company-not-found', companyNotFoundHandler);
    socket.on('email-already-exists', guestEmailErrorHandler);
    socket.on('job-post-not-found', jobPostNotFoundHandler);
    socket.on('assessment-already-done', assessmentAlreadyDoneHandler);
    socket.on('application-not-found', applicationNotFoundHandler);
    socket.on('job-post-archived', jobPostArchivedHandler);

    return () => {
      socket.off('connect', connectHandler);
      socket.off('disconnect', disconnectHandler);
      socket.off(
        'soft-skill-question-interview-chunk-processed',
        softSkillQuestionInterviewChunkProcessedHandler
      );
      socket.off('soft-skill-question-interview-preview', softSkillQuestionInterviewPreviewHandler);
      socket.off(
        'presentation-interview-chunk-processed',
        presentationInterviewChunkProcessedHandler
      );
      socket.off('job-post-found', jobPostFoundHandler);
      socket.off('exam-found', examFoundHandler);
      socket.off('assessment-updated', assessmentUpdatedHandler);
      socket.off('ok', okHandler);
      socket.off('killer-questions-failed', killerQuestionsFailed);
      socket.on('killer-questions-succeeded', killerQuestionsSucceeded);
      // Errors
      socket.off('email-already-exists', guestEmailErrorHandler);
      socket.off('company-not-found', companyNotFoundHandler);
      socket.off('job-post-not-found', jobPostNotFoundHandler);
      socket.off('assessment-already-done', assessmentAlreadyDoneHandler);
      socket.off('application-not-found', applicationNotFoundHandler);
      socket.off('job-post-archived', jobPostArchivedHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnected]);

  return { socket, connected: !disconnected };
};

export default useSocket;
