import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ExamSummaryStep } from '../../../interfaces';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import EditPencil from '../../../shared/components/Icons/EditPencil';
import VideoIcon from '../../../shared/components/Icons/VideoIcon';

interface Props {
  steps: ExamSummaryStep[];
}

const ExamProgram = ({ steps }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col justify-items-stretch rounded-lg border-[1px] border-solid border-[#d0d5dd]">
      {steps.map((step: ExamSummaryStep, index: number) => (
        <div
          key={`exam-summary-step-${index}`}
          className={clsx(
            'flex w-full flex-row items-center justify-between border-[#d0d5dd] px-3 py-2',
            { 'border-b-[1px]': index + 1 < steps.length }
          )}
        >
          <div className="flex flex-row items-center">
            <div className="mr-3 flex w-[50px] flex-1">
              {step.type === 'Custom Questions' && <EditPencil />}
              {step.type === 'Soft Skill' && <VideoIcon />}
              {step.type === 'Hard Skill' && <EditPencil />}
            </div>
            <div className="flex w-full flex-col items-start">
              <HeadingText
                className="font-bold"
                variant="primary-small"
              >
                {step.title}
              </HeadingText>
              <HeadingText variant="secondary">
                {step.type === 'Custom Questions' && t('summary.customQuestions.subtitle')}
                {step.type === 'Soft Skill' && step.type}
                {step.type === 'Hard Skill' && step.type}
              </HeadingText>
            </div>
          </div>
          <div className="mr-2 flex w-24 gap-2 text-end lg:w-52">
            <HeadingText
              variant="secondary"
              className="hidden flex-1 lg:block"
            >
              {step.questions}{' '}
              {step.questions <= 1
                ? t('examProgram.step.question')
                : t('examProgram.step.questions')}
            </HeadingText>
            {/* 
            Not needed for the moment
            <div className="hidden min-w-10 justify-start lg:flex">
              {step.completed && <CheckmarkIcon color="#06743f" />}
              {step.current > 0 && step.current < step.total && (
                <HeadingText
                  className="flex justify-center text-yellow-600"
                  variant="secondary"
                >
                  ({step.current}/{step.total})
                </HeadingText>
              )}
            </div> */}
            <HeadingText
              variant="secondary"
              className="min-w-16"
            >
              {step.duration >= 60 && <>{Math.round(step.duration / 60)} min</>}
              {step.duration < 60 && <>{step.duration} sec</>}
            </HeadingText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExamProgram;
