import { useEffect, useRef, useState } from 'react';
import { actions as examActions } from '../../../store/exam/reducer';
import { useAppDispatch } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import SummaryBox from './SummaryBox';

const Summary = () => {
  const dispatch = useAppDispatch();

  const [videoPermission, setVideoPermission] = useState<boolean>(false);
  const [audioPermission, setAudioPermission] = useState<boolean>(false);
  const [permissionsDenied, setPermissionsDenied] = useState<boolean>(false);
  const [permissionsError, setPermissionsError] = useState<boolean>(false);

  const stream = useRef<MediaStream | null>(null);

  const requirePermissionsHandler = async (): Promise<void> => {
    // require user permissions
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    } catch (e: unknown) {
      console.error(e);
      if (e instanceof Error && e.name === 'NotAllowedError') {
        // user deny permissions
        setPermissionsDenied(true);
        setVideoPermission(false);
        setAudioPermission(false);
      } else if (e instanceof Error && ['NotReadableError', 'AbortError'].includes(e.name)) {
        // TODO: If devices are busy camera could not start
        // user has devices busy
        setVideoPermission(true);
        setAudioPermission(true);
      } else {
        // any other errors
        setPermissionsError(true);
        setVideoPermission(false);
        setAudioPermission(false);
      }
    }
    try {
      if (stream && stream.current) {
        stream.current.getTracks().forEach((track: MediaStreamTrack) => {
          console.log('Acquired track:', track);
          if (track.kind === 'video') {
            setVideoPermission(track.enabled);
          }
          if (track.kind === 'audio') {
            setAudioPermission(track.enabled);
          }
          track.stop();
        });
      }
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const submitHandler = (): void => {
    dispatch(uiActions.SUMMARY_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
  };

  useEffect(() => {
    dispatch(uiActions.SUMMARY_START());
  }, [dispatch]);

  useEffect(() => {
    const checkPermissions = async () => {
      let camera_status: PermissionState;
      let microphone_status: PermissionState;
      try {
        const camera_permission: PermissionStatus = await window.navigator.permissions.query({
          name: 'camera' as PermissionName,
        });
        const microphone_permission: PermissionStatus = await window.navigator.permissions.query({
          name: 'microphone' as PermissionName,
        });
        camera_status = camera_permission.state;
        microphone_status = microphone_permission.state;
      } catch (e) {
        console.log('Error with Permissions api...');
        camera_status = 'prompt';
        microphone_status = 'prompt';
      }
      camera_status === 'granted' && setVideoPermission(true);
      microphone_status === 'granted' && setAudioPermission(true);
      camera_status === 'denied' && setVideoPermission(false);
      microphone_status === 'denied' && setAudioPermission(false);
      if (camera_status === 'denied' || microphone_status === 'denied') {
        setPermissionsDenied(true);
      } else {
        setPermissionsDenied(false);
      }
    };
    checkPermissions();
  }, []);

  return (
    <div className="m-[auto] flex w-full max-w-5xl flex-col items-center justify-center lg:h-full">
      <SummaryBox
        hasPermissions={videoPermission && audioPermission}
        permissionsDenied={permissionsDenied}
        permissionsError={permissionsError}
        onRequirePermissions={requirePermissionsHandler}
        onSubmit={submitHandler}
      />
    </div>
  );
};

export default Summary;
