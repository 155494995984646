import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import TriangleExclamationIcon from '../../../shared/components/Icons/TriangleExclamationIcon';
import TriangleRedExclamationIcon from '../../../shared/components/Icons/TriangleRedExclamationIcon';

interface Props {
  permissionsDenied: boolean;
  permissionsError: boolean;
  onRequirePermissions: () => void;
}

const AskPermissions = ({ permissionsDenied, permissionsError, onRequirePermissions }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center justify-center bg-[#f7f7f7] p-4',
        'lg:mt-4 lg:max-h-[64px] lg:flex-row lg:justify-start lg:rounded-[8px] lg:bg-[#FFFFFF]'
      )}
    >
      <div
        className={clsx(
          'flex h-[44px] w-[44px] flex-col items-center justify-center rounded-full',
          permissionsDenied || permissionsError ? 'bg-[#ED4545]' : 'bg-[#ffb319]'
        )}
      >
        {permissionsError && <TriangleRedExclamationIcon />}
        {permissionsDenied && <TriangleRedExclamationIcon />}
        {!permissionsDenied && !permissionsError && <TriangleExclamationIcon />}
      </div>
      <HeadingText
        variant="primary-small"
        className={clsx('m-4 flex-1 text-center font-medium', 'lg:text-left')}
      >
        {permissionsError && t('summary.askPermission.description.error')}
        {permissionsDenied && t('summary.askPermission.description.notAllowed')}
        {!permissionsDenied && !permissionsError && t('summary.askPermission.description')}
      </HeadingText>
      {!permissionsDenied && (
        <Button
          className="action"
          onClick={onRequirePermissions}
        >
          {t('summary.askPermission.button')}
        </Button>
      )}
    </div>
  );
};

export default AskPermissions;
