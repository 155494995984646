import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface GuestUiErrors {
  name?: string;
  surname?: string;
  email?: string;
}

export interface GuestUIState {
  loading: boolean;
  errors: GuestUiErrors;
}

export interface IntroUIState {
  loading: boolean;
}

export interface SummaryUIState {
  loading: boolean;
}

export interface BriefingUIState {
  loading: boolean;
  // speedtest_status: 'ready' | 'running' | 'unstable' | 'stable';
}

export interface FilterQuestionsUIState {
  loading: boolean;
}

export interface VideoInterviewUIState {
  loading: boolean;
  // recording_status: 'idle' | 'recording' | 'submitting' | 'done';
  // elapsed_time: number;
  // uploaded: boolean;
}

export interface QuestionnaireUIState {
  loading: boolean;
  // recording_status: 'idle' | 'recording' | 'submitting' | 'done';
  // elapsed_time: number;
  // uploaded: boolean;
}
export interface GuestInvitedUIState {
  name: string;
  surname: string;
  email: string;
}

export interface UIState {
  guest: GuestUIState;
  intro: IntroUIState;
  summary: SummaryUIState;
  briefing: BriefingUIState;
  filter_questions: FilterQuestionsUIState;
  presentation_interview: VideoInterviewUIState;
  video_interview: VideoInterviewUIState;
  questionnaire: QuestionnaireUIState;
  guestInvited: GuestInvitedUIState;
}

const initialState: UIState = {
  guest: {
    loading: false,
    errors: {} as GuestUiErrors,
  },
  intro: {
    loading: false,
  },
  summary: {
    loading: false,
  },
  briefing: {
    loading: false,
    // speedtest_status: 'ready',
  },
  filter_questions: {
    loading: false,
  },
  presentation_interview: {
    loading: false,
  },
  video_interview: {
    loading: false,
    // recording_status: 'idle',
    // elapsed_time: 0,
    // uploaded: false,
  },
  questionnaire: {
    loading: false,
    // recording_status: 'idle',
    // elapsed_time: 0,
    // uploaded: false,
  },
  guestInvited: {
    name: '',
    surname: '',
    email: '',
  },
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    GUEST_ERROR: (state, action: PayloadAction<{ name: string; error: string }>) => ({
      ...state,
      guest: {
        ...state.guest,
        errors: {
          ...state.guest.errors,
          [action.payload.name]: action.payload.error,
        },
      },
    }),
    GUEST_ERROR_RESET: (state, action: PayloadAction<string>) => {
      const errors: GuestUiErrors = { ...state.guest.errors };
      delete errors[action.payload as keyof GuestUiErrors];
      return {
        ...state,
        guest: {
          ...state.guest,
          errors,
        },
      };
    },
    GUEST_ERROR_RESET_ALL: (state) => ({
      ...state,
      guest: {
        ...state.guest,
        errors: {} as GuestUiErrors,
      },
    }),
    GUEST_SUBMIT: (state) => ({
      ...state,
      guest: {
        ...state.guest,
        loading: true,
      },
    }),
    INTRO_START: (state) => ({
      ...state,
      intro: {
        loading: false,
      },
    }),
    INTRO_SUBMIT: (state) => ({
      ...state,
      intro: {
        loading: true,
      },
    }),
    SUMMARY_START: (state) => ({
      ...state,
      summary: {
        loading: false,
      },
    }),
    SUMMARY_SUBMIT: (state) => ({
      ...state,
      summary: {
        loading: true,
      },
    }),
    BRIEFING_START: (state) => ({
      ...state,
      briefing: {
        loading: false,
      },
    }),
    BRIEFING_SUBMIT: (state) => ({
      ...state,
      briefing: {
        loading: true,
      },
    }),
    // VIDEOINTERVIEW_START: (state) => ({
    //   ...state,
    //   video_interview: {
    //     loading: false,
    //     recording_status: 'idle',
    //     elapsed_time: 0,
    //     uploaded: false,
    //   },
    // }),
    // VIDEOINTERVIEW_RECORD: (state) => ({
    //   ...state,
    //   video_interview: {
    //     ...state.video_interview,
    //     recording_status: 'recording',
    //   },
    // }),
    // VIDEOINTERVIEW_TICK: (state) => ({
    //   ...state,
    //   video_interview: {
    //     ...state.video_interview,
    //     elapsed_time: state.video_interview.elapsed_time + 1,
    //   },
    // }),
    FILTER_QUESTIONS_SUBMIT: (state) => ({
      ...state,
      filter_questions: {
        ...state.filter_questions,
        loading: false,
      },
    }),
    FILTER_QUESTIONS_SUBMITTED: (state) => ({
      ...state,
      filter_questions: {
        ...state.filter_questions,
        loading: true,
      },
    }),
    PRESENTATION_INTERVIEW_SUBMIT: (state) => ({
      ...state,
      presentation_interview: {
        ...state.presentation_interview,
        loading: false,
      },
    }),
    PRESENTATION_INTERVIEW_SUBMITTED: (state) => ({
      ...state,
      presentation_interview: {
        ...state.presentation_interview,
        loading: true,
      },
    }),
    VIDEOINTERVIEW_SUBMIT: (state) => ({
      ...state,
      video_interview: {
        ...state.video_interview,
        loading: false,
        // recording_status: 'submitting',
      },
    }),
    VIDEOINTERVIEW_SUBMITTED: (state) => ({
      ...state,
      video_interview: {
        ...state.video_interview,
        loading: true,
        // recording_status: 'done',
        // uploaded: true,
      },
    }),
    // QUESTIONNAIRE_START: (state) => ({
    //   ...state,
    //   questionnaire: {
    //     loading: false,
    //     recording_status: 'idle',
    //     elapsed_time: 0,
    //     uploaded: false,
    //   },
    // }),
    // QUESTIONNAIRE_RECORD: (state) => ({
    //   ...state,
    //   questionnaire: {
    //     ...state.questionnaire,
    //     recording_status: 'recording',
    //   },
    // }),
    // QUESTIONNAIRE_TICK: (state) => ({
    //   ...state,
    //   questionnaire: {
    //     ...state.questionnaire,
    //     elapsed_time: state.questionnaire.elapsed_time + 1,
    //   },
    // }),
    QUESTIONNAIRE_SUBMIT: (state) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: false,
        // recording_status: 'submitting',
      },
    }),
    QUESTIONNAIRE_SUBMITTED: (state) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: true,
        // recording_status: 'done',
        // uploaded: true,
      },
    }),
    GUEST_INVITED: (state, action: PayloadAction<GuestInvitedUIState>) => ({
      ...state,
      guest: {
        ...state.guest,
        loading: false,
      },
      guestInvited: {
        ...state.guestInvited,
        ...action.payload,
      },
    }),
  },
});

export default slice.reducer;

export const actions = { ...slice.actions };
