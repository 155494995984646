import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import EyeCloseIcon from '../../../shared/components/Icons/EyeCloseIcon';
import EyeOpenIcon from '../../../shared/components/Icons/EyeOpenIcon';
import RecLittleIcon from '../../../shared/components/Icons/RecLittleIcon';
import { getCountDown } from '../../../shared/utils/getCountDown';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCompletedChunks,
  getCurrentSoftSkillQuestionCounters,
  getCurrentStep,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';

const DURATION: number = +import.meta.env.VITE_APP_INTERVIEW_DURATION;
const MIN_INTERVIEW_DURATION: number = +import.meta.env.VITE_APP_MIN_INTERVIEW_DURATION;

interface ChunkPayload {
  id?: number;
  type: 'start' | 'chunk' | 'end';
  data?: ArrayBuffer;
  part: number;
  elapsed_time: number;
}

const VideoInterview = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const navigate = useNavigate();

  const { socket, connected } = useSocketInstance();

  // Selectors
  const completed_chunks = useAppSelector(getCompletedChunks);
  const currentStep = useAppSelector(getCurrentStep) as SoftSkillQuestionStep | null;
  const counters = useAppSelector(getCurrentSoftSkillQuestionCounters);

  // Refs
  const player = useRef<HTMLVideoElement | null>(null);
  const stream = useRef<MediaStream | undefined>();
  const recorder = useRef<MediaRecorder | undefined>();
  const chunkIndex = useRef<number>(0);
  const elapsed = useRef<number>(0);
  const completionPercentage = useRef<number>(10);
  const total_chunks = useRef<number>(0);
  const chunks = useRef<ChunkPayload[]>([]);

  // State
  const [height] = useState<number>(window.innerHeight);
  const [width] = useState<number>(window.innerWidth);
  const [overlay, setOverlay] = useState<boolean>(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(getCountDown(DURATION, elapsed?.current)); //This gets formatted to 03:00 string.
  const [completed, setCompleted] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  // Handlers
  const handleStart = () => {
    const payload: ChunkPayload = {
      id: currentStep?.data.question.id,
      type: 'start',
      part: chunkIndex.current,
      elapsed_time: elapsed.current,
    };
    chunks.current = [payload];
    chunkIndex.current = chunkIndex.current + 1;
  };

  const handleStop = (): void => {
    setRecording(false);
    stream.current?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    recorder.current && recorder.current.removeEventListener('dataavailable', handleDataChunk);
    recorder.current && recorder.current.removeEventListener('start', handleStart);
    recorder.current && recorder.current.removeEventListener('stop', handleStop);
    recorder.current && recorder.current.removeEventListener('error', handleError);
  };

  const stopButtonClickHandler = useCallback((): void => {
    setSubmitted(true);
    recorder.current && recorder.current.stop();
    setTimeout(() => {
      chunks.current = [
        ...chunks.current.sort((a: ChunkPayload, b: ChunkPayload) => a.part - b.part),
      ];
      const last_part: number =
        chunks.current.length > 0
          ? chunks.current[chunks.current.length - 1].part + 1
          : chunkIndex.current + 1;
      total_chunks.current = last_part;
      const payload: ChunkPayload = {
        id: currentStep?.data.question.id,
        type: 'end',
        part: last_part,
        elapsed_time: elapsed.current,
      };
      chunks.current = [...chunks.current, payload];
      setCompleted(true);
    }, 2000);
  }, [chunkIndex, elapsed, currentStep]);

  const handleError = (_e: unknown): void => {
    // TODO: Handler error event
  };

  const recordingHandler = async (): Promise<void> => {
    if (!recording) {
      setShowCountdown(true);
    } else {
      stopButtonClickHandler();
    }
  };

  const handleDataChunk = useCallback(
    async (e: BlobEvent) => {
      const payload: ChunkPayload = {
        id: currentStep?.data.question.id,
        type: 'chunk',
        data: await e.data.arrayBuffer(),
        part: chunkIndex.current,
        elapsed_time: elapsed.current,
      };
      chunks.current = [...chunks.current, payload].sort(
        (a: ChunkPayload, b: ChunkPayload) => a.part - b.part
      );
      chunkIndex.current = chunkIndex.current + 1;
    },
    [elapsed, currentStep]
  );

  // Effects
  // Handle video and audio stream from user devices
  useEffect(() => {
    const createStream = async (): Promise<void> => {
      if (player && player.current) {
        stream.current = await navigator.mediaDevices.getUserMedia({
          video: {
            width: width > 640 ? 640 : width,
            height: width > 640 ? 360 : height,
            aspectRatio: 16 / 9,
            frameRate: { ideal: 30 },
          },
          audio: {
            sampleSize: 16,
            channelCount: 1,
          },
        });
        try {
          player.current.srcObject = stream.current;
          player.current.onloadedmetadata = () => player.current?.play();
        } catch (e) {
          // Silently suppress eventual errors on page refresh
        }
      }
    };
    createStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // Handle recording elapsed time
  useEffect(() => {
    let recordingInterval: ReturnType<typeof setInterval> | undefined;

    if (recording) {
      recordingInterval = setInterval(() => {
        elapsed.current = elapsed.current + 1;
        setTimer(getCountDown(DURATION, elapsed.current));
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }

    return () => {
      clearInterval(recordingInterval);
    };
  }, [recording]);

  // Handle time expiration
  useEffect(() => {
    if (elapsed.current === DURATION) {
      stopButtonClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current, recorder]);

  // Handle stop recording button
  useEffect(() => {
    if (elapsed.current >= MIN_INTERVIEW_DURATION && !enableStop) {
      setEnableStop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current]);

  // Handle countdown when recording button is pressed
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (showCountdown) {
      interval = setInterval(() => {
        setCountdown((state: number) => state - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showCountdown]);

  // Handle countdown expiration
  useEffect(() => {
    if (countdown === 0 && !completed) {
      setShowCountdown(false);
      setRecording(true);

      if (stream && stream.current) {
        recorder.current = new MediaRecorder(stream.current);
        recorder.current.addEventListener('dataavailable', handleDataChunk);
        recorder.current.addEventListener('start', handleStart);
        recorder.current.addEventListener('stop', handleStop);
        recorder.current.addEventListener('error', handleError);
        /**
         * TODO:
         *
         * The number of milliseconds to record into each Blob.
         * If this parameter isn't included, the entire media duration is recorded into a single Blob
         * unless the requestData() method is called to obtain the Blob and trigger the creation of a new Blob into which the media continues to be recorded.
         *
         * If a timeslice property was passed into the MediaRecorder.start() method that started media capture,
         * a dataavailable event is fired every timeslice milliseconds.
         * That means that each blob will have a specific time duration
         * (except the last blob, which might be shorter, since it would be whatever is left over since the last event).
         * So if the method call looked like this — recorder.start(1000); — the dataavailable event would fire after each second of media capture,
         * and our event handler would be called every second with a blob of media data that's one second long.
         * You can use timeslice alongside MediaRecorder.stop() and MediaRecorder.requestData() to produce multiple same-length blobs plus other shorter blobs as well.
         */
        recorder.current.state === 'inactive' && recorder.current.start(1000);
        dispatch(examActions.VIDEO_INTERVIEW_START());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, completed, stream, recorder]);

  // Send chunks on active connection
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (connected && chunks.current.length > 0) {
      const chunk: ChunkPayload = chunks.current.shift() as ChunkPayload;
      if (chunk.type !== 'end') {
        socket.emit('soft-skill-question-stream', chunk);
      } else {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
          socket.emit('soft-skill-question-stream', chunk);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, chunks.current]);

  useEffect(() => {
    if (completed && connected) {
      if (chunks.current.length > 0) {
        let timeout: ReturnType<typeof setTimeout>;
        let deferred: ChunkPayload | undefined;
        while (chunks.current.length > 0) {
          const chunk: ChunkPayload = chunks.current.shift() as ChunkPayload;
          if (chunk.type === 'end') {
            deferred = chunk;
          } else {
            socket.emit('soft-skill-question-stream', chunk);
          }
        }
        if (deferred) {
          timeout = setTimeout(() => {
            clearTimeout(timeout);
            socket.emit('soft-skill-question-stream', deferred);
          }, 1000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, completed]);

  useEffect(() => {
    if (total_chunks.current > 0) {
      const percentage: number =
        100 - Math.round(((total_chunks.current - completed_chunks) / total_chunks.current) * 100);
      completionPercentage.current = percentage;
      // Check if it has been completed
      if (completionPercentage.current === 100) {
        // Check whether the soft skill has a retake and user has reached limit or the soft skills has no retake. Then redirect
        if (
          (currentStep &&
            currentStep.data.retake &&
            currentStep.data.retake.current + 1 === currentStep.data.retake.limit) ||
          (currentStep && !currentStep.data.retake)
        ) {
          navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-done`, {
            replace: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed_chunks]);

  if (!currentStep || currentStep.type !== 'soft-skill-question') {
    return null;
  }
  return (
    <div className="m-[auto] flex h-full w-full max-w-5xl flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        className={clsx(
          'm-[0 auto] relative flex h-full w-full flex-col items-center gap-6 overflow-hidden bg-white p-6',
          'lg:max-h-[576px] lg:max-w-[1024px] lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        <div className="flex w-full items-center justify-between">
          {/* Question counter */}
          <div className="text-lg font-bold">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>
          <div className="flex items-center gap-2">
            {/* Connection icon */}
            <ConnectionStatus
              type="icon"
              connected={connected}
            />
          </div>
        </div>
        <div
          className={clsx(
            'relative mb-12 flex w-full items-center rounded-lg bg-[#FFF0E6] p-6 pb-48',
            'lg:pb-24'
          )}
        >
          {/* Question text */}
          <div
            className={clsx(
              'max-h-52 overflow-y-auto text-sm font-semibold text-[#111]',
              'lg:max-h-36 lg:text-2xl'
            )}
          >
            {currentStep.data.question.text}
          </div>
          {/* Video Mirror */}
          {/* Video overlay */}
          {overlay && (
            <div
              className={clsx(
                'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                'h-32 w-32 rounded-full border-8 border-white bg-black bg-opacity-60 backdrop-blur-md',
                { 'z-10': overlay },
                { '-z-10': !overlay }
              )}
            ></div>
          )}
          <video
            ref={player}
            className={clsx(
              'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
              'h-32 w-32 rounded-full border-8 border-white object-cover'
            )}
            playsInline
            muted={true}
            controls={false}
          />

          {/* Overlay Button*/}
          <div
            className={clsx(
              'absolute -bottom-14 left-1/2 z-20 translate-x-8',
              'flex h-10 w-10 cursor-pointer items-center justify-center',
              'rounded-full border-2 border-white bg-[#f0f0f0] text-white backdrop-blur-lg'
            )}
            onClick={() => setOverlay((state: boolean) => !state)}
          >
            {overlay ? <EyeOpenIcon color="#000" /> : <EyeCloseIcon color="#000" />}
          </div>
        </div>
        {/* Footer */}
        <div className="absolute bottom-0 flex w-full flex-col items-center justify-center gap-12 lg:gap-14">
          {/* Retake counter with recording messages */}
          {currentStep.data.retake &&
            currentStep.data.retake.limit > 1 &&
            (recording ? (
              <span className="flex items-center gap-2 font-bold text-[#CF222E]">
                <RecLittleIcon color="#CF222E" />
                {/* (Minimum) Recording messages */}
                {enableStop
                  ? t('videoInterview.retake.counter.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })
                  : t('videoInterview.retake.counter.min.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })}
              </span>
            ) : (
              <span className="font-bold text-[#666666]">
                {t('videoInterview.retake.counter', {
                  attempt: currentStep.data.retake.current + 1,
                  retake: currentStep.data.retake.limit,
                })}
              </span>
            ))}
          {/* Recording messages */}
          {currentStep.data.retake && currentStep.data.retake.limit <= 1 && recording && (
            <span className="flex items-center gap-2 font-bold text-[#CF222E]">
              <RecLittleIcon color="#CF222E" />
              {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
            </span>
          )}

          {/* Rec Button */}
          <Button
            className={clsx(
              'mb-6 flex gap-2 bg-black lg:w-64',
              { success: !completed && recording && enableStop },
              { transparent: !completed && recording && !enableStop }
            )}
            disabled={completed || (!completed && recording && !enableStop)}
            onClick={
              (!completed && !recording) || (!completed && recording && enableStop)
                ? recordingHandler
                : () => null
            }
          >
            {/* Rec Button text */}
            {!completed && !recording && t('videoInterview.button.record')}
            {!completed && recording && !enableStop && t('videoInterview.button.min.record')}
            {!completed && recording && enableStop && t('videoInterview.button.recording')}
            {/* Rec timer */}
            <div className="translate-y-[1px] font-mono text-xl">{timer}</div>
          </Button>
        </div>

        <div className="flex items-center justify-center">
          {/* Upload Overlay */}
          <div
            className={clsx(
              'pointer-events-none absolute left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center gap-5 bg-white',
              { hidden: !submitted }
            )}
          >
            {/* Upload disclaimer text */}
            <div className="flex flex-col items-center gap-2">
              <div className="text-lg font-semibold text-[#111]">
                {t('videoInterview.upload.disclaimer.title')}
              </div>
              <div className="text-[#626262]">{t('videoInterview.upload.disclaimer.subtitle')}</div>
            </div>
            {/* Upload Bar */}
            <div className="flex w-full max-w-[256px] items-center gap-2">
              <div className="h-2 w-full overflow-hidden rounded-full bg-[#e2e2e2]">
                <div
                  style={{ width: `${completionPercentage.current}%` }}
                  className={clsx(
                    'h-full max-w-full rounded-full bg-gradient-to-r from-[#896efd] to-[#4f28f8] transition-all ease-linear'
                  )}
                />
              </div>
              {/* Upload percentage text */}
              <span className="text-xs text-[#666666]">{`${completionPercentage.current}%`}</span>
            </div>
            <div className="text-[#626262]">{t('videoInterview.upload.loader')}</div>
          </div>
        </div>
        {/* Countdown overlay */}
        <div
          className={clsx(
            'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-80 p-4 backdrop-blur-md',
            'lg:justify-center',
            { hidden: !showCountdown }
          )}
        >
          {/* Countdown */}
          <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full bg-[#ECECFE] text-6xl font-extrabold text-[#111] backdrop-blur-lg">
            {countdown}
          </div>

          {/* Title */}
          <div className="my-2 text-center text-2xl font-bold text-[#111]">
            {t('videoInterview.title')}
          </div>

          {/* Subtitle */}
          <div className="text-center font-semibold text-[#626262]">
            {t('videoInterview.subtitle')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoInterview;
