import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntroductionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { getCompanyData, getCurrentStep, getJobPostData } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getIntroLoading } from '../../../store/ui/selectors';
import DescriptionText from './DescriptionText';
import VideoPlayer from './VideoPlayer';

interface Props {
  onSubmit: () => void;
}

const IntroBox = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const currentStep = useAppSelector(getCurrentStep) as IntroductionStep | null;
  const jobPostData = useAppSelector(getJobPostData);
  const companyData = useAppSelector(getCompanyData);
  const loading = useAppSelector(getIntroLoading);

  const [checkboxes, setCheckboxes] = useState<{
    company_policy: boolean;
    algo_interview_policy: boolean;
    terms_and_conditions: boolean;
  }>({
    company_policy: false,
    algo_interview_policy: false,
    terms_and_conditions: false,
  });

  const allCheckboxesChecked: boolean = Object.values(checkboxes).every((value: boolean) => value);
  const ALGO_PRIVACY_POLICY_URL = import.meta.env.VITE_ALGO_PRIVACY_POLICY_URL;

  if (!currentStep || currentStep.type !== 'introduction') {
    return null;
  }

  return (
    currentStep &&
    jobPostData &&
    companyData && (
      <div className="m-[auto] flex w-full max-w-5xl items-center justify-center lg:h-full">
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-center bg-[#FFFFFF] pt-4',
            'lg:h-auto lg:rounded-[8px] lg:p-6 lg:shadow-cust-1'
          )}
        >
          <JobHeader />
          <div
            className={clsx(
              'my-6 flex w-full flex-col items-start justify-stretch px-4',
              'lg:max-h-[270px] lg:flex-row lg:px-0'
            )}
          >
            <div className="lg:h-100 flex-1 lg:overflow-hidden">
              <DescriptionText description={currentStep.data.introduction_content} />
            </div>
            {jobPostData.welcome_video_url && (
              <div className="lg:h-100 flex-1">
                <VideoPlayer video={jobPostData.welcome_video_url} />
              </div>
            )}
          </div>

          {!currentStep.data.policies_accepted && (
            <div className="w-full">
              <hr className="hidden h-[1px] w-full lg:my-6 lg:block" />
              {/* Disclaimer text */}
              <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
                <div
                  className={clsx('mb-4 w-full text-[#b37800]', { hidden: allCheckboxesChecked })}
                >
                  {t('intro.privacy.disclaimer')}
                </div>
                {/* CheckBoxes */}
                <div className="mb-20 flex w-full flex-col lg:mb-0">
                  {Object.keys(checkboxes).map((checkbox: string) => (
                    <Checkbox
                      key={`introduction-checkbox-${checkbox}`}
                      name={checkbox}
                      label={
                        checkbox === 'company_policy'
                          ? t(`intro.privacy.${checkbox}`, {
                              companyName: companyData.name,
                              companyPolicyUrl: companyData.privacy_policy_url,
                            })
                          : checkbox === 'algo_interview_policy'
                            ? t(`intro.privacy.${checkbox}`, {
                                algoPolicyUrl: ALGO_PRIVACY_POLICY_URL,
                              })
                            : t(`intro.privacy.${checkbox}`)
                      }
                      onChange={(checked: boolean) =>
                        setCheckboxes((state) => ({
                          ...state,
                          [checkbox]: checked,
                        }))
                      }
                    />
                  ))}
                </div>
              </div>
              {/* Button */}
              <div
                className={clsx(
                  'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
                  'lg:static lg:justify-end lg:border-0 lg:pb-0'
                )}
              >
                <Button
                  loading={loading}
                  disabled={!allCheckboxesChecked}
                  className="default"
                  onClick={onSubmit}
                >
                  {t('intro.button.start')}
                </Button>
              </div>
            </div>
          )}
          {/* Coming from guest sign in, button is enabled by default */}
          {currentStep.data.policies_accepted && (
            <div
              className={clsx(
                'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
                'lg:static lg:justify-end lg:border-0 lg:pb-0'
              )}
            >
              <Button
                loading={loading}
                className="default"
                onClick={onSubmit}
              >
                {t('intro.button.start')}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default IntroBox;
