import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSocketInstance } from '../../../App';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { EMAIL_REGEX } from '../../../shared/utils/regex';
import { getCompanyData } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { GuestUiErrors, actions } from '../../../store/ui/reducer';
import { getGuestErrors, getGuestLoading } from '../../../store/ui/selectors';

interface FormState {
  name: { value: string; valid: boolean; touched: boolean };
  surname: { value: string; valid: boolean; touched: boolean };
  email: { value: string; valid: boolean; touched: boolean };
}

const ALGO_PRIVACY_POLICY_URL = import.meta.env.VITE_ALGO_PRIVACY_POLICY_URL;

const Guest = () => {
  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // Selectors
  const loading = useAppSelector(getGuestLoading);
  const company = useAppSelector(getCompanyData);
  const errors = useAppSelector(getGuestErrors);

  // States
  const [state, setState] = useState<FormState>({
    name: { value: '', valid: false, touched: false },
    surname: { value: '', valid: false, touched: false },
    email: { value: '', valid: false, touched: false },
  });

  const [formErrors, setFormErrors] = useState<GuestUiErrors>({});

  const validateInput = (value: string, type: string): boolean => {
    let valid = false;
    let error = '';
    switch (type) {
      case 'name':
      case 'surname': {
        valid = value.trim().length >= 2;
        error = t('guest.input.name.error');
        break;
      }
      case 'email': {
        valid = EMAIL_REGEX.test(value.trim());
        error = t('guest.input.email.error');
        break;
      }
    }
    if (!valid) {
      setFormErrors((state) => ({
        ...state,
        [type as keyof GuestUiErrors]: error,
      }));
    } else {
      const newState = { ...formErrors };
      delete newState[type as keyof GuestUiErrors];
      setFormErrors(newState);
    }
    return valid;
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setState((prev: FormState) => ({
      ...prev,
      [e.target.name as keyof FormState]: {
        value: e.target.value,
        valid: validateInput(e.target.value, e.target.name),
        touched: true,
      },
    }));
  };

  const submitHandler = async (): Promise<void> => {
    dispatch(actions.GUEST_SUBMIT());
    dispatch(
      actions.GUEST_INVITED({
        name: state.name.value,
        surname: state.surname.value,
        email: state.email.value,
      })
    );
    socket.emit('guest-authenticate', {
      name: state.name.value.trim(),
      surname: state.surname.value.trim(),
      email: state.email.value.trim(),
    });
  };

  const [checkboxes, setCheckboxes] = useState<{
    company_policy: boolean;
    algo_interview_policy: boolean;
    terms_and_conditions: boolean;
  }>({
    company_policy: false,
    algo_interview_policy: false,
    terms_and_conditions: false,
  });

  const allCheckboxesChecked: boolean = Object.values(checkboxes).every((value: boolean) => value);

  useEffect(() => {
    dispatch(actions.GUEST_ERROR_RESET_ALL());
  }, []);

  useEffect(() => {
    if (formErrors.name) {
      dispatch(actions.GUEST_ERROR({ name: 'name', error: formErrors['name'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('name'));
    }
  }, [formErrors.name]);

  useEffect(() => {
    if (formErrors.surname) {
      dispatch(actions.GUEST_ERROR({ name: 'surname', error: formErrors['surname'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('surname'));
    }
  }, [formErrors.surname]);

  useEffect(() => {
    if (formErrors.email) {
      dispatch(actions.GUEST_ERROR({ name: 'email', error: formErrors['email'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('email'));
    }
  }, [formErrors.email]);

  return (
    <div className="m-[auto] flex w-full max-w-[500px] items-center justify-center lg:h-full">
      <div
        className={clsx(
          'flex h-full w-full flex-col items-center justify-center bg-[#FFFFFF] pt-4',
          'lg:h-auto lg:rounded-[8px] lg:p-6 lg:shadow-cust-1'
        )}
      >
        <JobHeader logoOnly={true} />

        <div
          className={clsx(
            'mt-6 flex w-full flex-col items-start justify-stretch px-4',
            'lg:max-h-[270px] lg:flex-row lg:px-0'
          )}
        >
          <HeadingText
            className="font-semibold"
            variant="primary-small"
          >
            {t('guest.heading')}
          </HeadingText>
        </div>

        <div
          className={clsx(
            'mt-6 flex w-full flex-col items-start justify-stretch px-4',
            'lg:max-h-[270px] lg:px-0'
          )}
        >
          <div className="mb-4 w-full">
            <label
              htmlFor="name"
              className="mb-2 block text-[15px]"
            >
              {t('guest.form.name.label')}
            </label>
            <input
              required
              id="name"
              name="name"
              className={clsx(
                'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                { 'border-[2px] border-[#df1b41]': errors['name'] }
              )}
              type="text"
              placeholder={t('guest.form.name.placeholder')}
              value={state.name.value}
              onChange={changeHandler}
            />
            {errors['name'] && <div className="text-xs text-[#df1b41]">{errors['name']}</div>}
          </div>
          <div className="mb-4 w-full">
            <label
              htmlFor="surname"
              className="mb-2 block text-[15px]"
            >
              {t('guest.form.surname.label')}
            </label>
            <input
              required
              id="surname"
              name="surname"
              className={clsx(
                'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                { 'border-[2px] border-[#df1b41]': errors['surname'] }
              )}
              type="text"
              placeholder={t('guest.form.surname.placeholder')}
              value={state.surname.value}
              onChange={changeHandler}
            />
            {errors['surname'] && <div className="text-xs text-[#df1b41]">{errors['surname']}</div>}
          </div>
          <div className="mb-4 w-full">
            <label
              htmlFor="email"
              className="mb-2 block text-[15px]"
            >
              {t('guest.form.email.label')}
            </label>
            <input
              required
              id="email"
              name="email"
              className={clsx(
                'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600 focus:border-[2px] focus:border-[#4344dc] disabled:cursor-not-allowed disabled:opacity-50',
                { 'border-[2px] border-[#df1b41]': errors['email'] }
              )}
              type="email"
              placeholder={t('guest.form.email.placeholder')}
              value={state.email.value}
              onChange={changeHandler}
            />
            {errors['email'] && <div className="text-xs text-[#df1b41]">{errors['email']}</div>}
          </div>
        </div>

        <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
          <div className={clsx('my-4 w-full text-[#b37800]', { hidden: allCheckboxesChecked })}>
            {t('guest.privacy.disclaimer')}
          </div>
          <div className="mb-20 flex w-full flex-col lg:mb-0">
            {Object.keys(checkboxes).map((checkbox: string) => (
              <Checkbox
                key={`introduction-checkbox-${checkbox}`}
                name={checkbox}
                label={
                  checkbox === 'company_policy'
                    ? t(`guest.privacy.${checkbox}`, {
                        companyName: company?.name,
                        companyPolicyUrl: company?.privacy_policy_url,
                      })
                    : checkbox === 'algo_interview_policy'
                      ? t(`guest.privacy.${checkbox}`, {
                          algoPolicyUrl: ALGO_PRIVACY_POLICY_URL,
                        })
                      : t(`guest.privacy.${checkbox}`)
                }
                onChange={(checked: boolean) =>
                  setCheckboxes((state) => ({
                    ...state,
                    [checkbox]: checked,
                  }))
                }
              />
            ))}
          </div>
        </div>
        <div
          className={clsx(
            'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
            'lg:static lg:justify-center lg:border-0 lg:pb-0'
          )}
        >
          <Button
            loading={loading}
            disabled={
              !allCheckboxesChecked ||
              !state.name.valid ||
              !state.surname.valid ||
              !state.email.valid
            }
            className="default flex-1"
            onClick={submitHandler}
          >
            {t('guest.button.start')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Guest;
